<template>
  <div class="Cumulative_achievements">
    <div class="cu-content">
      <div class="group">
        <div class="text">累计打卡：{{ clockTotal }}天</div>
        <div class="text">接到项目：{{ projectTotoal }}单</div>
      </div>
      <img
        class="close"
        src="../../assets/images/cumulative_achievements/close.png"
        alt="关闭"
        @click="close"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clockTotal: "",
      projectTotoal: "",
    };
  },
  mounted() {
    this.getAchievement();
  },
  methods: {
    // 获取数据
    getAchievement() {
      this.$api.user.achievement().then((res) => {
        let { clockTotal, projectTotoal } = res.data;
        this.clockTotal = clockTotal;
        this.projectTotoal = projectTotoal;
      });
    },
    close() {
      this.$router.push("/painter/take_orders");
    },
  },
};
</script>

<style lang="less" scoped>
.Cumulative_achievements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
  .cu-content {
    width: 50%;
    margin: 0 auto;
    background: url("../../assets/images/cumulative_achievements/bg.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 5vw;
    .group {
      font-size: 23px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #fbed4b;
      .text {
        line-height: 3vw;
      }
    }
    .close {
      margin-top: 2vw;
      cursor: pointer;
    }
  }
}
</style>
